@media (max-width: 767px){.tlg-hidden-xs {
  display: none !important;
}

.tlg-overflow-x-scroll-mobile {
  overflow-x: scroll;
  scrollbar-width: none;
}

.tlg-overflow-x-scroll-mobile::-webkit-scrollbar {
  display: none;
}

.tlg-fullwidth {
  --margin: calc(var(--padding-container) * -1);
}

:root {
  --fontH1: 3.2rem;
  --fontH2: 2.4rem;
  --fontH3: 2rem;
  --fontH4: 1.6rem;
  --fontH5: 1.8rem;
  --fontH6: 1.5rem;
}

.tlg-detail-mentions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tlg-detail-mentions .tlg-btn-premium {
  white-space: nowrap;
}

.tlg-detail-footer .tlg-btn-premium {
  margin-bottom: 1.6rem;
}}